import * as endpoints from "../config/endpoints";
import { useTransactionContext } from "../providers";
import { PaymentMethodMetadata } from "../types";
import { useFetch } from "../utils/reactQuery";

type PaymentMethodMetadataResponse = {
  message: PaymentMethodMetadata;
};

export const usePaymentMetadata = () => {
  const { transaction } = useTransactionContext();
  const { transactionType } = transaction;

  const { data, isLoading, refetch } = useFetch<PaymentMethodMetadataResponse>(
    `${endpoints.paymentTypes}`,
    { type: transactionType ?? "buy" },
    {
      enabled: false,
    }
  );
  return {
    paymentMetadata: data?.message,
    isLoading,
    refetch,
  };
};
