import { useCallback, useEffect, useState } from "react";
import { isAndroid, isMobile } from "react-device-detect";
import * as endpoints from "../config/endpoints";
import { APPLE_PAY, CREDIT_CARD } from "../constants";
import { useTransactionContext } from "../providers";
import { useParamContext } from "../providers/ParamContextProvider/ParamContextProvider";
import { PaymentMethod, TransactionType } from "../types";
import { removeObjectsInSuppliedArray } from "../utils/commonUtils";
import { useFetch } from "../utils/reactQuery";
import { useDefaults } from "./useDefaults";

export type PaymentMethodsResponse = {
  message: PaymentMethod[];
};

type FetchParams = {
  country?: string;
  type?: TransactionType;
  isRecurringPayment?: boolean;
  destination?: string;
};

export const usePaymentMethods = () => {
  const { transaction, setSelectedPaymentMethod } = useTransactionContext();
  const {
    selectedFiat,
    selectedCrypto,
    selectedCountry,
    transactionType,
    isRecurringPayment,
  } = transaction;
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const { params } = useParamContext();
  const { country } = params;
  const { excludePaymentMethods, defaultPaymentMethod } = transactionType
    ? params[transactionType]
    : params.buy;
  const { countryDefault } = useDefaults(
    country ?? selectedCountry?.countryCode.toLowerCase(),
    transactionType ?? "buy"
  );
  const fetchParams: FetchParams = {
    type: transactionType ?? "buy",
    isRecurringPayment,
    destination:
      transactionType === "sell" ? selectedFiat?.id : selectedCrypto?.id,
  };

  if (country) {
    fetchParams.country = country;
  } else if (selectedCountry) {
    fetchParams.country = selectedCountry.countryCode.toLowerCase();
  }

  const { data, isLoading, error, isError, refetch } =
    useFetch<PaymentMethodsResponse>(
      `${endpoints.paymentTypes}/${transactionType === "sell" ? selectedCrypto?.id : selectedFiat?.id}`,
      fetchParams,
      { enabled: false }
    );

  useEffect(() => {
    if (data?.message) {
      let filterBasedOnDevice: PaymentMethod[] = [];
      if (isMobile && isAndroid) {
        filterBasedOnDevice = data?.message.filter(
          (p) => p.paymentTypeId !== APPLE_PAY
        );
      } else {
        filterBasedOnDevice = data.message;
      }
      setPaymentMethods(filterBasedOnDevice);
    }
  }, [data?.message]);

  useEffect(() => {
    if (excludePaymentMethods) {
      const pms = removeObjectsInSuppliedArray(
        data?.message ?? [],
        "paymentTypeId",
        excludePaymentMethods.toLowerCase().split(",")
      );
      setPaymentMethods(pms);
    }
  }, [data?.message, excludePaymentMethods]);

  const getPaymentMethodById = useCallback(
    (paymentTypeId: string | undefined) =>
      paymentMethods &&
      paymentMethods.find((p) => p.paymentTypeId === paymentTypeId),
    [paymentMethods]
  );

  const selectInitialPaymentMethod = useCallback(() => {
    const pmPreference: string[] = [];

    if (defaultPaymentMethod) {
      pmPreference.push(defaultPaymentMethod);
    }

    if (
      !countryDefault?.disableDeviceSpecificRecommendation &&
      window.ApplePaySession
    ) {
      pmPreference.push(APPLE_PAY);
    }

    if (countryDefault?.paymentMethod) {
      pmPreference.push(countryDefault?.paymentMethod);
    }

    // Give preference to CC if nothing found
    pmPreference.push(CREDIT_CARD);

    for (let i = 0; i < pmPreference.length; i++) {
      const pm =
        paymentMethods &&
        paymentMethods.find(
          (p) =>
            p.paymentTypeId === pmPreference[i] &&
            p.details?.currencyStatus !== "DestNotSupported"
        );
      if (pm) {
        setSelectedPaymentMethod(pm);
        break;
      } else {
        setSelectedPaymentMethod(
          paymentMethods && paymentMethods.length > 0 ? paymentMethods[0] : null
        );
      }
    }
  }, [
    countryDefault?.disableDeviceSpecificRecommendation,
    countryDefault?.paymentMethod,
    defaultPaymentMethod,
    paymentMethods,
    setSelectedPaymentMethod,
  ]);

  const getRecommendedPaymentMethod = useCallback(
    (paymentMethods?: PaymentMethod[]) => {
      if (window.ApplePaySession) {
        return APPLE_PAY;
      } else if (countryDefault?.paymentMethod) {
        return countryDefault?.paymentMethod;
      } else if (paymentMethods && paymentMethods.length > 0)
        return paymentMethods[0].paymentTypeId;
      else return CREDIT_CARD;
    },
    [countryDefault?.paymentMethod]
  );

  return {
    paymentMethods: paymentMethods ?? [],
    isLoading,
    isError,
    error,
    refetch,
    getPaymentMethodById,
    selectInitialPaymentMethod,
    getRecommendedPaymentMethod,
  };
};
